<template src="./NGWord.html"></template>
<style lang="scss" src="./NGWord.scss"></style>

<script lang="ts">
import {checkState} from '@/stores/state/check/check'
import {MEDIA_ID, NG_TYPE, NGWord,} from '@/stores/model/domain'
import {ErrorUploadNGWord, ngWordRepository, RequestUploadNGWord,} from '@/stores/repository/ng_word'
import {auth} from '@/stores/authorization-state'
import {CSVReader, Row} from '@/csv-reader'
import {CSVDownloader} from '@/csv-downloader'
import {CSVDownloadContents} from '@/csv-download-contents'
import {MESSAGE_FAILED_TO_DELETE_DATA, MESSAGE_UPLOAD_NG_WORDS_COMPLETE,} from '@/constants'
import {Format} from '@/utils'
import {computed, defineComponent, ref} from 'vue'

const NGWordComponent = defineComponent({
  components: {},
  setup() {
    // InputFile element
    const inputFile = ref()
    // Upload File
    const isFileValid = ref < boolean > (false)
    const isFileError = ref < boolean > (false)
    const fileName = ref < string | null > (null)
    const errorMessage = ref < string | null > (null)
    const uploadNGWords = ref < RequestUploadNGWord[] > ([])

    const isLoading = () => {
      return checkState.loading.main
    }

    const ngWordTypeFormat = (ngType: NG_TYPE) => {
      return Format.ngWordType(ngType)
    }

    // Client
    const selectedClient = computed(() => {
      return checkState.selectedClientCompany
    })

    // NGWord
    const ngWords = computed(() => {
      return checkState.ngWords
    })

    // File
    const resetInput = () => {
      isFileValid.value = false
      isFileError.value = false
      errorMessage.value = null
      fileName.value = null
      uploadNGWords.value = []
    }

    const reload = () => {
      const clientCompany = checkState.selectedClientCompany
      if (!clientCompany) {
        return;
      }
      checkState.setLoadingOn()
      ngWordRepository.fetchNGWords({
        token: auth.token,
        cmClientCompanyId: clientCompany.cm_client_company_id,
      }).then((ngws: NGWord[]) => {
        checkState.setNGWords(ngws)
        checkState.setLoadingOff()
      })
    }

    const clickDragdropArea = () => {
      // クリックしたとき、ファイル選択ダイアログが開くようにする
      inputFile.value.click()
    }

    const setFile = (event: any) => {
      resetInput()
      const files = event.target.files ? event.target.files : event.dataTransfer.files
      const file: File = files[0]
      const clientCompany = checkState.selectedClientCompany
      if (!clientCompany) { return }
      const clientCompanyId = clientCompany.cm_client_company_id

      csvToUploadNGWord(file)
          .then(removeErrorEmptyUploadNGWord)
          .then((upNGWs) => {
            if (upNGWs.length <= 0) {
              return Promise.reject([{
                row_num: 0,
                errorMessage: 'マッチする行がありません',
              }])
            }
            checkState.setLoadingOn()
            return ngWordRepository.check({
              token: auth.token,
              user: auth.employeeNumber,
              cmClientCompanyId: clientCompanyId,
              requestUploadNGWords: upNGWs,
            }).then((validUploadNGWords) => {
              uploadNGWords.value = validUploadNGWords
              fileName.value = file.name
              isFileValid.value = true
              checkState.setLoadingOff()
            })
          }).catch((errorNGWords: ErrorUploadNGWord[]) => {
        errorMessage.value = errorNGWords.map((errorNGWord) => {
          return `${errorNGWord.row_num}行目：${errorNGWord.error_message}`
        }).join('<br/>')
        isFileError.value = true
        checkState.setLoadingOff()
      })
    }

    const csvToUploadNGWord = (file: File) => {
      if (file) {
        const reader = new CSVReader()
        return reader.read(file, true).then((rows: Row[]) => {
          return rows.map((row, index) => {
            // 空行の場合はnullを返す
            if (row.values.some((value) => value)) {
              // aitd upload id と 審査AI ID
              const aitdUploadId = row.values[1].trim() || null
              const inspectorRuleId = row.values[2].trim() || null
              // 審査AI IDを持つ（審査AI経由でインポート）NGWordはcsv upload対象外
              if (!!inspectorRuleId) {
                return null
              }

              // NGワード
              const ngWord = row.values[3]
              if (ngWord.length <= 0) {
                return {
                  row_num: index + 1,
                  error_message: `NGワードを入力して下さい。`,
                }
              }
              // 代替ワード
              const replacedWord = row.values[4].trim() || null
              // NG理由
              const reason = row.values[5].trim() || null
              // NG種別
              const ngType = replacedWord ? NG_TYPE.AVAILABLE_REPLACE : NG_TYPE.ABSOLUTELY_NG
              // MediaId、AccountId、CampaignId、AdGroupId
              let mediaId: MEDIA_ID | null = null
              if (row.values[6] === '1') {
                mediaId = MEDIA_ID.YAHOO
              } else if (row.values[6] === '2') {
                mediaId = MEDIA_ID.GOOGLE
              } else if (row.values[6] === '7') {
                mediaId = MEDIA_ID.BING
              }
              const accountId = row.values[7].trim() || null
              if ((mediaId && !accountId) || (!mediaId && accountId)) {
                return {
                  row_num: index + 1,
                  error_message: `AccountIdを指定する場合はMediaIdとセットで入力してください。`,
                }
              }
              const campaignId = row.values[8].trim() || null
              if (!accountId && campaignId) {
                return {
                  row_num: index + 1,
                  error_message: `CampaignIdを指定する場合は、MediaId及びAccountIdを入力してください。`,
                }
              }
              let adGroupId = row.values[9].trim() || null
              if ((!accountId || !campaignId) && adGroupId) {
                return {
                  row_num: index + 1,
                  error_message: `AdGroupIdを指定する場合は、MediaId、AccountId及びCampaignIdを入力してください。`,
                }
              }
              return {
                row_num: index + 1,
                aitd_upload_id: aitdUploadId,
                ng_word: ngWord,
                replaced_word: replacedWord,
                reason,
                ng_type: ngType,
                media_id: mediaId,
                account_id: accountId,
                campaign_id: campaignId,
                adgroup_id: adGroupId,
              }
            } else {
              return null
            }
          })
        })
      } else {
        return Promise.reject([{
          row_num: 0,
          error_message: 'CSVファイルロードに失敗しました',
        }])
      }
    }

    // csvのAssetをまとめてアップロード＆結果をcsvダウンロード
    const execUploadNGWords = () => {
      isFileError.value = false
      errorMessage.value = ''
      const clientCompany = checkState.selectedClientCompany
      if (!clientCompany) {
        return
      }
      const clientCompanyId = clientCompany.cm_client_company_id
      if (uploadNGWords.value.length <= 0) {
        return
      }
      checkState.setLoadingOn()
      ngWordRepository.postUpload({
        token: auth.token,
        user: auth.employeeNumber,
        cmClientCompanyId: clientCompanyId,
        requestUploadNGWords: uploadNGWords.value,
      }).then((_) => {
        ngWordRepository.fetchNGWords({
          token: auth.token,
          cmClientCompanyId: clientCompanyId,
        }).then((ngws: NGWord[]) => {
          checkState.setNGWords(ngws)
          checkState.setLoadingOff()
          alert(MESSAGE_UPLOAD_NG_WORDS_COMPLETE)
        })
      }).catch((errors: string[]) => {
        isFileError.value = true
        errorMessage.value = errors.toString()
        checkState.setLoadingOff()
        return
      })
    }

    const removeErrorEmptyUploadNGWord = (uploadNGWord: Array<RequestUploadNGWord | ErrorUploadNGWord | null>) => {
      const errorNGWords: ErrorUploadNGWord[] = []
      const filtered: RequestUploadNGWord[] = uploadNGWord.filter((ngWord) => {
        if (!ngWord) {
          return false
        } else if (Object.keys(ngWord).indexOf('error_message') !== -1) {
          errorNGWords.push(ngWord as ErrorUploadNGWord)
          return false
        }
        return true
      }) as RequestUploadNGWord[]
      if (errorNGWords.length > 0) {
        return Promise.reject(errorNGWords)
      }
      return Promise.resolve(filtered)
    }

    const downloadFormat = () => {
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const content = downloadContents.generateNGWordFormat()
      downloader.download(content, 'NGWord_Format')
    }

    const deleteNGWord = (ngWord: NGWord) => {
      const clientCompany = checkState.selectedClientCompany
      if (!clientCompany) { return }
      if (confirm(`【${ngWord.ng_word}】 削除しますか？`)) {
        checkState.setLoadingOn()
        ngWordRepository.deleteNGWord({
          token: auth.token,
          cmClientCompanyId: clientCompany.cm_client_company_id,
          ngWord,
        }).then((ngws) => {
          checkState.setNGWords(ngws)
          checkState.setLoadingOff()
        }).catch((e) => {
          // エラー表示
          alert(MESSAGE_FAILED_TO_DELETE_DATA)
          checkState.setLoadingOff()
        })
      }
    }

    const onClickDownload = () => {
      const ngws = checkState.ngWords
      if (ngws.length <= 0) { return }
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const content = downloadContents.generateNGWordContent(ngws)
      downloader.download(content, 'NGWords')
    }

    return {
      inputFile,
      isFileValid,
      isFileError,
      fileName,
      errorMessage,
      uploadNGWords,
      isLoading,
      ngWordTypeFormat,
      selectedClient,
      ngWords,
      resetInput,
      reload,
      clickDragdropArea,
      setFile,
      execUploadNGWords,
      downloadFormat,
      deleteNGWord,
      onClickDownload,
    }
  },
})

export default NGWordComponent
</script>
