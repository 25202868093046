import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

export interface WordCheckRequest {
  text: string
  uuid: string
}

interface WordCheckResponse extends DataServiceResponse {
  text: string
  uuid: string
  is_grammatical: boolean
}

interface WordCheckResponses extends DataServiceResponse {
  results: WordCheckResponse[]
}

class WordCheckRepository {
  public async checkWordFormat(data: {
        assets: WordCheckRequest[],
        token: string,
      }): Promise<WordCheckResponses> {
    const endpoint = `/word_format_check`
    return DataService.post<WordCheckResponses>({
      path: endpoint,
      param: {
        assets: data.assets,
      },
      token: data.token,
    }).then((wcrs) => {
      return wcrs
    })
  }
}

export const wordCheckRepository = new WordCheckRepository()
