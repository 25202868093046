<div>
    <h2><i class="fas fa-caret-right"></i>RSA制作</h2>
    <div v-show="!!selectedClient" class="produce_rsa">
        <div class="">
            <Multiselect
                    class=""
                    placeholder="アカウント名で検索"
                    v-model="selectedAccountValue"
                    value-prop="value"
                    :options="selectAccountOptions"
                    label="label"
                    track-by="track_by"
                    :searchable="true"
                    :option-height="20"
                    :limit="1000"
                    @select="onSelectAccount">
            </Multiselect>
            <div class="account-id" v-if="!!selectedAccount">ACCOUNTID:{{ selectedAccount.account_id }}（{{ selectedAccount.media_id }}）</div>
        </div>

        <div class="adgroup_select">
            <Multiselect
                    class=""
                    placeholder="キャンペーン・アドグループ名で検索"
                    v-model="selectedAdGroupValue"
                    value-prop="value"
                    :options="selectAdGroupOptions"
                    label="label"
                    track-by="track_by"
                    :searchable="true"
                    :option-height="20"
                    :limit="1000"
                    @select="onSelectAdGroup">
            </Multiselect>
            <div class="cmpadg" v-if="!!selectedAdGroup">CMPID:{{ selectedAdGroup.campaign_id }} ADGID:{{ selectedAdGroup.adgroup_id }}</div>
        </div>

        <div class="kw_imp_area">
            <h2><i class="fas fa-caret-right"></i>上位キーワード（imp, conv）</h2>
            <ul>
                <li v-for="k in highKeywordsImp" :class="kwBgColor(k)">{{ k.keyword }}({{ k.imp }}, {{ k.conv }})</li>
            </ul>
            <h2 @click="toggleLowKeywordsVisibility" class="low_keyword"><i class="fas fa-caret-right"></i>下位キーワード（imp, conv）<i v-if="isLowKeywordsVisible" class="fas fa-chevron-down"></i><i v-else class="fas fa-chevron-up"></i></h2>
            <ul v-show="isLowKeywordsVisible">
                <li v-for="k in lowKeywordsImp" :class="kwBgColor(k)">{{ k.keyword }}({{ k.imp }}, {{ k.conv }})</li>
            </ul>
        </div>

        <div class="ng_word_area">
            <h2 @click="toggleNGWordsVisibility" class="ng_word"><i class="fas fa-caret-right"></i>NGワード（代替ワード）<i v-if="isNGWordsVisible" class="fas fa-chevron-down"></i><i v-else class="fas fa-chevron-up"></i></h2>
            <ul v-show="isNGWordsVisible">
                <li v-for="ngw in ngWords">{{ ngw.ng_word }}<span v-if="ngw.replaced_word" class="replaced_word">{{ ngw.replaced_word }}</span></li>
            </ul>
        </div>

        <div class="create_content">
            <div class="create_content_left">
                <h2><i class="fas fa-caret-right"></i>スコア判定</h2>

                <div class="asset_table_header">
                    <div>
                        <span class="btn_selection" v-for="s in SELECTION_COUNT">
                            <button class="btn"
                                    :class="[filterLogicColor(s), {'selected' : s == selectedPriority}]"
                                    @click="switchPreCombinedAdAsset(s, true)"
                                    :disabled="!(s - 1 in preCombinedCandidateAds)">{{ s }}</button>
                        </span>
                    </div>
                    <div class="banned_asset_btn" @click="onClickBanButton">
                        アセット削除
                    </div>
                </div>

                <div class="cr_table">
                    <div class="table_header">
                        <table>
                            <tr>
                                <th class="reset"></th>
                                <th class="rsa_kw">キーワード</th>
                                <th class="pin">ピン留め</th>
                                <th class="rsa_check"></th>
                                <th class="rsa_asset">アセット</th>
                                <th class="count_number"></th>
                                <th class="ng_word">チェック結果</th>
                                <th class="banned_asset" v-if="isShowBanButton">アセット削除</th>
                            </tr>
                        </table>
                    </div>

                    <div class="table_scroll">
                        <table>
                            <tr v-for="(ca, index) in caTitles">
                                <td class="reset"><button class="btn" :disabled="ca.asset_text.length <= 0" @click="onClickDeleteTitle(index)">初期化</button></td>
                                <td class="rsa_kw">{{ ca.keyword? ca.keyword: '-' }}</td>
                                <td class="pin">
                                    <select v-model="ca.pinned_field">
                                        <option
                                            v-for="(pinnedField) in pinnedFieldHeadlines"
                                            :value="pinnedField"
                                        >{{ pinnedFieldFormat(pinnedField) }}</option>
                                    </select>
                                </td>
                                <td class="rsa_check">
                                    <div class="checkbox">
                                        {{ box_id = "t_box" + index }}
                                        <input :id="box_id"
                                               type="checkbox"
                                               :value="ca.generator_asset_id"
                                               v-model="ca.is_editable"
                                               @change="onChangeCheckbox(ca)">
                                        <label :for="box_id"></label>
                                    </div>
                                </td>
                                <td class="rsa_asset">
                                    <div class="input_text">
                                        <span class="ng_word_tagged" v-html="ca.asset_text_ng_tagged" />
                                        <input type="text"
                                               placeholder="タイトル"
                                               v-model="ca.asset_text"
                                               @input="onChangeInput(ca)"
                                               @blur="onBlurInput"
                                               v-bind:disabled="!ca.is_editable" />
                                    </div>
                                </td>
                                <td class="count_number">
                                    <span :class="{'error': isOverLength(ca)}">{{ textFullLength(ca.asset_text) }}文字</span>
                                </td>
                                <td class="ng_word">
                                    <div v-for="(ngCheckType) in ca.ng_check_types">
                                        <span class="warning">{{ ngCheckTypeWithoutReplace(ngCheckType) }}</span>
                                    </div>
                                    <div v-show="!ca.is_grammatical"><span class="warning">日本語注意</span></div>
                                </td>
                                <td class="banned_asset" v-if="isShowBanButton">
                                    <button class="btn" :disabled="isDisableOpenBanAsset(ca)" @click="onClickOpenBanAsset(ca)">アセット削除</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="cr_table">
                    <div class="table_header">
                        <table>
                            <tr>
                                <th class="reset"></th>
                                <th class="rsa_kw">キーワード</th>
                                <th class="pin">ピン留め</th>
                                <th class="rsa_check"></th>
                                <th class="rsa_asset">アセット</th>
                                <th class="count_number"></th>
                                <th class="ng_word">チェック結果</th>
                                <th class="banned_asset" v-if="isShowBanButton">アセット削除</th>
                            </tr>
                        </table>
                    </div>

                    <div class="table_scroll">
                        <table>
                            <tr v-for="(ca, index) in caDescriptions">
                                <td class="reset"><button class="btn" :disabled="ca.asset_text.length <= 0" @click="onClickDeleteDescription(index)">初期化</button></td>
                                <td class="rsa_kw">{{ ca.keyword? ca.keyword: '-' }}</td>
                                <td class="pin">
                                    <select v-model="ca.pinned_field">
                                        <option
                                            v-for="(pinnedField) in pinnedFieldDescriptions"
                                            :value="pinnedField"
                                        >{{ pinnedFieldFormat(pinnedField) }}</option>
                                    </select>
                                </td>
                                <td class="rsa_check">
                                    <div class="checkbox">
                                        {{ box_id = "d_box" + index }}
                                        <input :id="box_id"
                                               type="checkbox"
                                               :value="ca.generator_asset_id"
                                               v-model="ca.is_editable"
                                               @change="onChangeCheckbox(ca)">
                                        <label :for="box_id"></label>
                                    </div>
                                </td>
                                <td class="rsa_asset">
                                    <div class="input_text">
                                        <span class="ng_word_tagged" v-html="ca.asset_text_ng_tagged" />
                                        <textarea placeholder="説明文"
                                                  v-model="ca.asset_text"
                                                  @input="onChangeInput(ca)"
                                                  @blur="onBlurInput"
                                                  v-bind:disabled="!ca.is_editable"></textarea>
                                    </div>
                                </td>
                                <td class="count_number">
                                    <span :class="{'error': isOverLength(ca)}">{{ textFullLength(ca.asset_text) }}文字</span>
                                </td>
                                <td class="ng_word">
                                    <div v-for="(ngCheckType) in ca.ng_check_types">
                                        <span class="warning">{{ ngCheckTypeWithoutReplace(ngCheckType) }}</span>
                                    </div>
                                    <div v-show="!ca.is_grammatical"><span class="warning">日本語注意</span></div>
                                </td>
                                <td class="banned_asset" v-if="isShowBanButton">
                                    <button class="btn" :disabled="isDisableOpenBanAsset(ca)" @click="onClickOpenBanAsset(ca)">アセット削除</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="create_content_right">
                <h2><i class="fas fa-caret-right"></i>配信中アセット</h2>

                <div class="url" v-if="championRSAAd">
                    <div class="ad_id">
                        <div class="name">[ID]</div>
                        <div class="val">{{ championRSAAd.ad_id }}</div>
                    </div>
                    <div class="final_url">
                        <div class="name">[最終ページURL]</div>
                        <div class="val">
                            <span v-if="championRSAAd.final_url" >
                                <a :href="championRSAAd.final_url" target="_blank">{{ championRSAAd.final_url }}</a>
                            </span>
                            <span v-else>ー</span>
                        </div>
                    </div>
                    <div class="path">
                        <div class="name">[表示URLパス1]</div>
                        <div class="val">{{ championRSAAd.path_1 }}</div>
                    </div>
                    <div class="path">
                        <div class="name">[表示URLパス2]</div>
                        <div class="val">{{ championRSAAd.path_2 }}</div>
                    </div>
                </div>

                <div v-show="!!championAdErrorMessage" class="error">
                    <span v-html="championAdErrorMessage"></span>
                </div>

                <!-- RSA Tiles -->
                <div class="cr_table delivery_asset_table">
                    <div class="table_header">
                        <table>
                            <tr>
                                <th>アセット</th>
                                <th class="pin">ピン留め</th>
                                <th class="imp">Imp</th>
                                <th class="ng_word">NGワード</th>
                            </tr>
                        </table>
                    </div>

                    <div class="table_scroll">
                        <table v-if="!!championRSAAd" >
                            <tr v-for="rsa_title_asset in championRSAAd.title_assets">
                                <td>
                                    <span class="ng_word_tagged" v-html="rsa_title_asset.asset_text_ng_tagged" />
                                </td>
                                <td class="pin">{{ pinnedFieldFormat(rsa_title_asset.pinned_field) }}</td>
                                <td class="imp">{{ rsa_title_asset.imp }}</td>
                                <td class="ng_word">
                                  <div v-for="(ngCheckType) in rsa_title_asset.ng_check_types">
                                    {{ ngCheckTypeWithoutReplace(ngCheckType) }}
                                  </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- RSA Descriptions -->
                <div class="cr_table delivery_asset_table">
                    <div class="table_header">
                        <table>
                            <tr>
                                <th>アセット</th>
                                <th class="pin">ピン留め</th>
                                <th class="imp">Imp</th>
                                <th class="ng_word">NGワード</th>
                            </tr>
                        </table>
                    </div>

                    <div class="table_scroll">
                        <table v-if="!!championRSAAd" >
                            <tr v-for="rsa_description_asset in championRSAAd.description_assets">
                                <td>
                                    <span class="ng_word_tagged" v-html="rsa_description_asset.asset_text_ng_tagged" />
                                </td>
                                <td class="pin">{{ pinnedFieldFormat(rsa_description_asset.pinned_field) }}</td>
                                <td class="imp">{{ rsa_description_asset.imp }}</td>
                                <td class="ng_word">
                                    <div v-for="(ngCheckType) in rsa_description_asset.ng_check_types">
                                        {{ ngCheckTypeWithoutReplace(ngCheckType) }}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="register_action">
            <div>
                <p class="error" v-for="e in rsaProductErrorMessages">{{ e }}</p>
            </div>
            <button class="btn run" @click="postRSAProduct(false)">登録</button>
            <button class="btn run" @click="postRSAProduct(true)">登録&納品確認</button>
            <button class="btn run" :disabled="!selectedAdGroup" @click="onClickOpenHistory(false, null)">履歴</button>
            <button class="btn run" :disabled="!selectedAdGroup" @click="onClickOpenHistory(true, null)">ADG履歴</button>
        </div>

        <!--サブコンポーネント リアルタイムアセット生成-->
        <LLMAssetGeneratorComponent ref="llmAssetGeneratorComponent" @updateLLMAssets="updateLLMAssets" />

        <!--スコア表示を固定////////////////////-->
        <div class="footer_floating">
            <div class="footer_floating_content">
                <div class="footer_floating_left">
                    <div class="evaluate">
                        <div class="evaluate_asset">
                            <div class="effect">広告の有効性：<span class="val">{{ adStrengthFormat(caAdStrength) }}</span></div>
                            <div class="score" v-bind:class="isScoreHigher ? 'active' : 'default'">スコア：<span class="val">{{ scoreFormat(caScore) }}</span></div>
                            <button class="btn clipboard" @click="onClickClipBoardCandidateAssets"><i class="fas fa-clipboard-list"></i></button>
                            <button class="btn upload" :disabled="caTitles.length+caDescriptions.length<=0" @click="estimateCandidateAssets">スコア判定</button>
                            <button class="btn" :disabled="caTitles.length+caDescriptions.length<=0" @click="checkJapaneseFormat">日本語チェック</button>
                        </div>
                        <div class="action">
                            <button class="btn reload" :disabled="!canCombineLLMAssets" @click="combineLLMAssets">組み合わせ</button>
                        </div>
                    </div>
                </div>
                <div class="footer_floating_right">
                    <div class="evaluate">
                        <div class="evaluate_asset">
                            <div class="effect delivery_effect">配信中広告の有効性：<span class="val">{{ adStrengthFormat(rsaAdStrength) }}</span></div>
                            <div class="score delivery_score">配信中ADスコア：<span class="val">{{ scoreFormat(rsaAdScore) }}</span></div>
                        </div>
                        <div class="action">
                            <button class="btn clipboard" @click="onClickClipBoardChampionRSAAd"><i class="fas fa-clipboard-list"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Modal-->
    <ProduceRSAHistoryModal ref="produceRSAHistoryModal" @editRSAProduct="editRSAProduct" />
    <BannedAssetModal ref="bannedAssetModal" @afterBanAsset="afterBanAsset" />
</div>
