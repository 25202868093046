import {
  AD_STRENGTH, AdGroup,
  ASSET_TYPE,
  MEDIA_ID,
  RSAProduct,
  RSAProductAsset,
  RSAProductWithAssets,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

export interface CheckAsset {
  asset_type: ASSET_TYPE,
  asset_text: string,
}

interface RSAProductResponse extends DataServiceResponse {
  rsa_product_id: number
  cm_client_company_id: string
  media_id: MEDIA_ID
  account_id: string
  account_name: string
  campaign_id: string
  campaign_name: string
  adgroup_id: string
  adgroup_name: string
  rsa_product_score: number
  ad_strength: AD_STRENGTH
  pre_combined_rsa_id: string | null
  tracking_id: string
  created_user: string
  created_at: string
  updated_user: string | null
  updated_at: string | null
  shipped_user: string | null
  shipped_at: string | null
  shipped_key: string | null
}

interface RSAProductWithAssetsResponse extends RSAProductResponse {
  assets: RSAProductAsset[]
}

interface RSAProductResponses extends DataServiceResponse {
  results: RSAProductResponse[]
}

interface RSAProductCheckResponse extends DataServiceResponse {
  errors: string[]
}

interface RSAProductShipResponse extends DataServiceResponse {}

function convertResponseToModel(rpr: RSAProductResponse): RSAProduct {
  return {
    rsa_product_id: rpr.rsa_product_id,
    cm_client_company_id: rpr.cm_client_company_id,
    media_id: rpr.media_id,
    account_id: rpr.account_id,
    account_name: rpr.account_name,
    campaign_id: rpr.campaign_id,
    campaign_name: rpr.campaign_name,
    adgroup_id: rpr.adgroup_id,
    adgroup_name: rpr.adgroup_name,
    rsa_product_score: rpr.rsa_product_score,
    ad_strength: rpr.ad_strength,
    pre_combined_rsa_id: rpr.pre_combined_rsa_id,
    tracking_id: rpr.tracking_id,
    created_user: rpr.created_user,
    created_at: rpr.created_at,
    updated_user: rpr.updated_user,
    updated_at: rpr.updated_at,
    shipped_user: rpr.shipped_user,
    shipped_at: rpr.shipped_at,
    shipped_key: rpr.shipped_key,
  }
}

function convertResponseToModelWithAssets(rpar: RSAProductWithAssetsResponse): RSAProductWithAssets {
  // tmp_uuidをリセット
  const { v7: uuidv7 } = require('uuid');

  return Object.assign(
    convertResponseToModel(rpar),
    { assets: rpar.assets.map((rpa) => {
        return Object.assign(rpa, {
          tmp_uuid: uuidv7(),
          ng_check_types: new Set(),
          asset_text_ng_tagged: rpa.asset_text,
          is_grammatical: true,
        })
      })},
  )
}

class RSAProductRepository {
  public async fetchByAMKey(data: {
        token: string,
        media_id: number,
        account_id: string,
        campaign_id: string,
        adgroup_id: string,
      }): Promise<RSAProduct[]> {
    const endpoint = `/rsa_products`
    return DataService.get<RSAProductResponses>({
      path: endpoint,
      param: {
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
      },
      token: data.token,
    }).then((rprs) => {
      return rprs.results.map((rpr) => {
        return convertResponseToModel(rpr)
      })
    })
  }

  public async fetchByPK(data: {
    token: string,
    rsa_product_id: number,
  }): Promise<RSAProductWithAssets> {
    const endpoint = `/rsa_products/assets`
    return DataService.get<RSAProductWithAssetsResponse>({
      path: endpoint,
      param: {
        rsa_product_id: data.rsa_product_id,
      },
      token: data.token,
    }).then((rpr) => {
      return convertResponseToModelWithAssets(rpr)
    })
  }

  public async check(data: {
    token: string,
    check_assets: CheckAsset[],
    adgroup: AdGroup,
    free_label: string | null
  }): Promise<RSAProductCheckResponse> {
    const endpoint = `/rsa_products/check`
    return DataService.post<RSAProductCheckResponse>({
      path: endpoint,
      param: {
        check_assets: data.check_assets,
        free_label: data.free_label,
        account_id: data.adgroup.account_id,
        media_id: data.adgroup.media_id,
        campaign_id: data.adgroup.campaign_id,
        adgroup_id: data.adgroup.adgroup_id,
      },
      token: data.token,
    })
  }

  public async ship(data: {
    token: string,
    rsa_product_id: number,
    free_label: string | null
    user: string,
  }): Promise<void> {
    const endpoint = `/rsa_products/ship`
    return DataService.put<RSAProductShipResponse>({
      path: endpoint,
      param: {
        rsa_product_id: data.rsa_product_id,
        free_label: data.free_label,
        ship_user: data.user,
      },
      token: data.token,
    }).then(() => {
      return
    })
  }
}

export const rsaProductRepository = new RSAProductRepository()
