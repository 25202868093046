import {
  Account, AD_STRENGTH, ASSET_PINNED_FILED, ASSET_TYPE, BulkDownloadPreCombinedAsset, MEDIA_ID,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface BulkPreCombinedAssetResponses extends DataServiceResponse {
  results: BulkPreCombinedAssetResponse[]
  error_adgroup_ids: string[]
}

interface BulkPreCombinedAssetResponse {
  account_id: string
  media_id: MEDIA_ID
  campaign_id: string
  adgroup_id: string
  pre_combined_rsa_id: string
  selection_priority: number
  is_new: boolean
  candidate_word: string
  candidate_asset_type: ASSET_TYPE
  candidate_pinned_field: ASSET_PINNED_FILED | null
  candidate_ad_strength: AD_STRENGTH | null
  candidate_score: number | null
  champion_ad_id: string | null
  champion_word: string | null
  champion_asset_type: ASSET_TYPE | null
  champion_pinned_field: ASSET_PINNED_FILED | null
  champion_score: number | null
  champion_imp: number | null
}

class BulkUploadPreCombinedAssetRepository {

  public async download(data: {
    token: string,
    user: string,
    account: Account,
    adgIds: string[],
    selection_count: number,
  }): Promise<BulkDownloadPreCombinedAsset[]> {
    const endpoint = `/pre_combined_asset/bulk/download`
    return DataService.post<BulkPreCombinedAssetResponses>({
      path: endpoint,
      param: {
        account_id: data.account.account_id,
        media_id: data.account.media_id,
        adgroup_ids: data.adgIds,
        selection_count: data.selection_count,
      },
      token: data.token,
    }).then((bpcas) => {
      if (bpcas.error_adgroup_ids.length > 0) {
        const errorInvalidAdGroupIdMessage = '有効なCampaign・AdGroupが存在しないか、同期が完了していません。<br/>' +
          bpcas.error_adgroup_ids.join('<br/>') + '<br/>'
        return Promise.reject(errorInvalidAdGroupIdMessage)
      }
      return bpcas.results.map((bpca) => {
        return {
          account_id: bpca.account_id,
          media_id: bpca.media_id,
          campaign_id: bpca.campaign_id,
          adgroup_id: bpca.adgroup_id,
          pre_combined_rsa_id: bpca.pre_combined_rsa_id,
          selection_priority: bpca.selection_priority,
          is_new: bpca.is_new,
          candidate_word: bpca.candidate_word,
          candidate_asset_type: bpca.candidate_asset_type,
          candidate_pinned_field: bpca.candidate_pinned_field,
          candidate_ad_strength: bpca.candidate_ad_strength,
          candidate_score: bpca.candidate_score,
          champion_ad_id: bpca.champion_ad_id,
          champion_word: bpca.champion_word,
          champion_asset_type: bpca.champion_asset_type,
          champion_pinned_field: bpca.champion_pinned_field,
          champion_score: bpca.champion_score,
          champion_imp: bpca.champion_imp,
        }
      })
    })
  }
}

export const bulkUploadPreCombinedAssetRepository = new BulkUploadPreCombinedAssetRepository()
