import {
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  CandidateAsset,
  MEDIA_ID,
  RSAAsset,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

export interface CandidateAssetResponse {
  generator_asset_id: number | null
  media_id: MEDIA_ID | null
  campaign_id: string
  adgroup_id: string
  keyword_id: string | null
  keyword: string | null
  match_type: string | null
  asset_type: ASSET_TYPE
  asset_text: string
  generated_date: string | null
  pinned_field: ASSET_PINNED_FILED | null
}

interface RSAAdAssetEstimateResponse extends DataServiceResponse {
  rsa_score: number
  ad_strength: number
}

interface RSAAdAssetCombineResponses extends DataServiceResponse {
  rsa_score: number
  ad_strength: number
  candidate_assets: CandidateAssetResponse[]
}

interface RSAAdAssetPostResponse extends DataServiceResponse {
  rsa_product_id: number
}

class RSAAdRepository {
  public async estimateRSAAsset(data: {
    token: string,
    media_id: number,
    account_id: string,
    campaign_id: string,
    adgroup_id: string,
    rsaAssets: RSAAsset[],
  }): Promise<RSAAdAssetEstimateResponse> {
    const endpoint = `/rsa_ad/asset/estimate`
    return DataService.post<RSAAdAssetEstimateResponse>({
      path: endpoint,
      param: {
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
        assets: data.rsaAssets,
      },
      token: data.token,
    }).then((aer) => {
      return aer
    }).catch((e) => {
      return Promise.reject(e.toString())
    })
  }

  public async combineRSAAsset(data: {
    token: string,
    media_id: number,
    account_id: string,
    campaign_id: string,
    adgroup_id: string,
    rsaAssets: RSAAsset[],
    llmCandidateAssets: CandidateAsset[],
  }): Promise<RSAAdAssetCombineResponses> {
    const endpoint = `/rsa_ad/asset/combine`
    return DataService.post<RSAAdAssetCombineResponses>({
      path: endpoint,
      param: {
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
        rsa_assets: data.rsaAssets,
        candidate_assets: data.llmCandidateAssets.map((llmca) => {
          return {
            generator_asset_id: null,
            media_id: llmca.media_id,
            campaign_id: llmca.campaign_id,
            adgroup_id: llmca.adgroup_id,
            keyword_id: llmca.keyword_id,
            keyword: llmca.keyword,
            generated_date: null,
            match_type: llmca.match_type,
            asset_type: llmca.asset_type,
            word: llmca.asset_text,
          }
        }),
      },
      token: data.token,
    }).then((arrs) => {
      return arrs
    }).catch((e) => {
      return Promise.reject(e.toString())
    })
  }

  public async postRSAAsset(data: {
    token: string,
    user: string,
    cm_client_company_id: string,
    media_id: number,
    account_id: string,
    account_name: string,
    campaign_id: string,
    campaign_name: string,
    adgroup_id: string,
    adgroup_name: string,
    pre_combined_rsa_id: string | null,
    caAssets: CandidateAsset[],
  }): Promise<number> {
    const endpoint = `/rsa_ad/asset`
    return DataService.post<RSAAdAssetPostResponse>({
      path: endpoint,
      param: {
        cm_client_company_id: data.cm_client_company_id,
        media_id: data.media_id,
        account_id: data.account_id,
        account_name: data.account_name,
        campaign_id: data.campaign_id,
        campaign_name: data.campaign_name,
        adgroup_id: data.adgroup_id,
        adgroup_name: data.adgroup_name,
        created_user: data.user,
        pre_combined_rsa_id: data.pre_combined_rsa_id,
        assets: data.caAssets.map((ca) => {
          return {
            generator_asset_id: ca.generator_asset_id,
            generated_date: ca.generated_date,
            keyword_id: ca.keyword_id,
            keyword: ca.keyword,
            match_type: ca.match_type,
            asset_type: ca.asset_type,
            asset_text: ca.asset_text,
            asset_text_default: ca.asset_text_default,
            is_editable: ca.is_editable,
            pinned_field: ca.pinned_field,
          }
        }),
      },
      token: data.token,
    }).then((apr) => {
      return apr.rsa_product_id
    }).catch((e) => {
      return Promise.reject(e.toString())
    })
  }
}

export const rsaAdRepository = new RSAAdRepository()
