<div v-if="isActiveModal" class="produce_rsa_shipping">
    <div class="overlay upper"></div>
    <div class="modal upper">
    <h2>RSA納品</h2>
    <div class="cr_table">
        <div class="table_header">
            <table>
                <tr>
                    <th class="asset_text"></th>
                    <th class="asset_type">アセットタイプ</th>
                    <th class="is_new">新規</th>
                    <th class="pinned_field">ピン留め</th>
                    <th class="text_length">文字数</th>
                    <th class="ng_word">チェック結果</th>
                </tr>
            </table>
        </div>

        <div class="table_scroll">
            <table v-if="!!optRsaProductWithAssets">
                <tr v-for="asset in optRsaProductWithAssets.assets">
                    <td class="asset_text">
                        <span class="ng_word_tagged" v-html="asset.asset_text_ng_tagged" />
                    </td>
                    <td class="asset_type">{{ assetTypeFormat(asset.asset_type) }}</td>
                    <td class="is_new">{{ asset.is_new ? '新規' : '-' }}</td>
                    <td class="pinned_field">{{ pinnedFieldNumFormat(asset.pinned_field) }}</td>
                    <td class="text_length">
                        <span :class="{ 'error' : isOverLength(asset)}">{{ textLength(asset.asset_text) }}</span>
                    </td>
                    <td class="ng_word">
                        <div v-for="(ngCheckType) in asset.ng_check_types">
                            <span class="warning">{{ ngCheckTypeFormatForRSA(ngCheckType) }}</span>
                        </div>
                        <div v-show="!asset.is_grammatical"><span class="warning">日本語注意</span></div>
                    </td>
                </tr>
            </table>
            <div class="no_content" v-show="!optRsaProductWithAssets">
                <span>RSA納品情報がありません</span>
            </div>
        </div>
    </div>

    <div>
        <p class="label_preview">
            <span>{{ labelPreview }}</span>
        </p>
        <div class="input_text">
            <label>任意入力ラベル</label>
            <input type="text" placeholder="ラベルを入力" v-model="inputFreeLabel">
        </div>
        <p class="error" v-show="isInvalidLabel">使用できない文字列が含まれています</p>
        <div>
            <p class="error" v-for="e in errorMessages">{{ e }}</p>
        </div>
    </div>

    <div class="modal_action">
        <button class="btn cancel" @click="closeProduceRSAShippingModal">Cancel</button>
        <button class="btn run" :disabled="errorMessages.length > 0 || isInvalidLabel" @click="onClickShipConfirm">やりとりAIへ納品</button>
    </div>
    </div>
</div>
