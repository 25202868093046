import {
  MATCH_TYPE,
  KEYWORD_TYPE,
  ASSET_TYPE,
  AD_STRENGTH,
  NG_TYPE,
  ASSET_PINNED_FILED,
} from '@/stores/model/domain';

export function numberFormat(value: number, min: number, max: number) {
    if (isNaN(value)) {
        value = 0;
    }
    const formatter = Intl.NumberFormat('ja-JP', { minimumFractionDigits: min,
                                                   maximumFractionDigits: max});
    return formatter.format(value);
}

export const Format = {
  status(status: string | null) {
    if (status === '0') {
      return 'OFF';
    } else if (status === '1') {
      return 'ON';
    } else if (status === '9') {
      return '削除';
    } else {
      return '不明';
    }
  },
  matchType(matchType: MATCH_TYPE) {
    // Mediaによって大文字小文字が異なるので、LowerCaseで合わせて表示対応する
    if (matchType === MATCH_TYPE.EXACT) {
      return '完全一致';
    } else if (matchType === MATCH_TYPE.BROAD) {
      return '部分一致';
    } else if (matchType === MATCH_TYPE.PHRASE) {
      return 'フレーズ一致';
    } else {
      return '不明';
    }
  },
  keywordType(keywordType: KEYWORD_TYPE) {
    if (keywordType === KEYWORD_TYPE.BY_CLIENT) {
      return 'クライアント共通';
    } else if (keywordType === KEYWORD_TYPE.BY_KEYWORD) {
      return 'キーワードごと';
    } else {
      return '不明';
    }
  },
  assetType(assetType: ASSET_TYPE) {
    if (assetType === ASSET_TYPE.TITLE) {
      return 'Title';
    } else if (assetType === ASSET_TYPE.DESCRIPTION) {
      return 'Description';
    } else {
      return '不明';
    }
  },
  amount(amount: number) {
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    });
    return formatter.format(amount);
  },
  adStrengthAsJPN(as: AD_STRENGTH | null) {
    if (as === AD_STRENGTH.INCOMPATIBLE) {
      return '不適合';
    } else if (as === AD_STRENGTH.POOR) {
      return '低い';
    } else if (as === AD_STRENGTH.AVERAGE) {
      return '平均的';
    } else if (as === AD_STRENGTH.GOOD) {
      return '良い';
    } else if (as === AD_STRENGTH.EXCELLENT) {
      return '非常に良い';
    } else {
      return '-';
    }
  },
  ngWordType(ngType: NG_TYPE) {
    if (ngType === NG_TYPE.ABSOLUTELY_NG) {
      return '完全NG';
    } else if (ngType === NG_TYPE.AVAILABLE_REPLACE) {
      return '置換可';
    } else {
      return '不明';
    }
  },
  ngCheckType(ngType: NG_TYPE) {
    if (ngType === NG_TYPE.ABSOLUTELY_NG) {
      return '完全NG';
    } else if (ngType === NG_TYPE.AVAILABLE_REPLACE) {
      return '置換済';
    } else {
      return '不明';
    }
  },
  ngCheckTypeWithoutReplace(ngType: NG_TYPE) {
    if (ngType === NG_TYPE.ABSOLUTELY_NG) {
      return '完全NG';
    } else if (ngType === NG_TYPE.AVAILABLE_REPLACE) {
      return '代替ワード';
    } else {
      return '不明';
    }
  },
  pinnedFieldNum(pinnedField: ASSET_PINNED_FILED | null) {
    // pinned_field 数字だけ出すようにフォーマット修正
    if (!pinnedField) { return '-' }
    return pinnedField.toString()
      .replace('HEADLINE_', '')
      .replace('DESCRIPTION_', '')
  },
};
